<template>
  <div>
    <div class="bg" />
    <div class="container">
      <div class="info">
        <img src="@/assets/ps.png" />
        <div class="detail">
          <div class="name">{{name}}</div>
          <div class="tel">{{tel}}</div>
        </div>
      </div>
      <div class="list">
        <div class="listitem" @click="onScan">
          <img src="@/assets/c1.png" class="icon" />
          <div class="text">扫一扫</div>
          <img src="@/assets/arrow.png" class="arrow" />
        </div>
      </div> 
           
    </div>
  </div>
</template>

<script>
import { Toast, Dialog } from 'vant';
import qs from 'qs';
import { isEmpty, isTel } from '@/utils';
export default {
  data() {
    return {
      data: {
        // 1-管理员 2-负责人 3-游客
        /*site_code: '测试场所',
        user_type: '大厨',
        nick_name: '李某某',
        mobile: '13359665321'*/
      },
      typeflag:false,
      name: '赵某某',
      tel: '18555695632',
      unionid: '',
      site_code: '',
      user_type: '',
      on_duty: '',
      clientcode: '',
      hrefurl:'',
      openid:''
    };
  },
  methods: {
    onScan() {
     
      window.wx.scanQRCode({
        needResult: 1,
        scanType: ['qrCode', 'barCode'],
        success: res => {
          let result = res.resultStr;
          this.scanResult(result);
        },
        error: res => {
          Dialog({ message: res });
        }
      });
    },
    async scanResult(code) {
      let params = qs.parse(code.split('?')[1]);
      let { SiteCode, ClientID } = params || {};
      if (SiteCode && ClientID) {
        this.$router.replace({path:'/medical/input_pass', query:{
          client_id:ClientID,
          stie_code:SiteCode,
          name:this.name,
          tel:this.tel,
          unionid:this.unionid
        }});
      } else {
          Dialog('二维码解析失败');
      }
    }
  },
  async created() { 
    this.name = this.$route.query.name;
    this.tel = this.$route.query.tel;
    this.unionid = this.$route.query.unionid;
    let openid = this.$route.query.openid;
    if (openid !== null && openid !== undefined && openid.length !== 0) {
      window.sessionStorage.setItem('openid', openid); 
    }

    this.hrefurl = location.href;
    window.sessionStorage.setItem('hrefurl', this.hrefurl);
    let config = (
      await this.axios.post(
        '/medicines/yd_tlService/jsapisign',
        qs.stringify({
          url: location.href
        })
      )
    ).data.data;
    window.wx.config({
      debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: config.appid, // 必填，公众号的唯一标识
      timestamp: config.timestamp, // 必填，生成签名的时间戳 ${wxConfig.timestamp}
      nonceStr: config.nonce_str, // 必填，生成签名的随机串 ${wxConfig.nonceStr}
      signature: config.signature, // 必填，签名，见附录1 ${wxConfig.signature}
      jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
    });

  }
};
</script>

<style lang="less" scoped>
.bg {
  height: 330px;
}
.content {
  margin-top: 60px;
}
.list {
  width: 710px;
  margin: 0px auto;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
  &.list2 {
    margin-top: 20px;
  }
  .listitem {
    overflow: hidden;
    height: 100px;
    border-radius: 8px;
    .icon {
      width: 64px;
      margin: 18px 0 18px 40px;
      display: block;
      float: left;
    }
    .arrow {
      float: right;
      display: block;
      margin-top: 32px;
      margin-right: 38px;
      height: 40px;
    }
    .text {
      font-size: 32px;
      float: left;
      margin-left: 20px;
      line-height: 44px;
      margin-top: 30px;
    }
    .right {
      line-height: 40px;
      color: #666666;
      font-size: 28px;
      float: right;
      margin-top: 32px;
      margin-right: 50px;
    }
  }
}
.info {
  overflow: hidden;
  margin: 90px 54px 62px;
  img {
    width: 120px;
    height: 120px;
    border-radius: 120px;
    display: block;
    float: left;
  }
  .detail {
    overflow: hidden;
    margin-left: 46px;
    float: left;
    .name {
      margin-top: 20px;
      line-height: 46px;
      color: #fff;
      font-size: 32px;
    }
    .tel {
      height: 40px;
      font-size: 28px;
      font-weight: 400;
      line-height: 40px;
      color: rgba(255, 255, 255, 1);
      opacity: 0.6;
    }
  }
}
</style>